import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

export type GetMerchantResponseData = {
  address: bnplMerchants.MerchantExcludingIsBlocked["address"];
  archived: bnplMerchants.MerchantExcludingIsBlocked["archived"];
  created?: Date;
  id: bnplMerchants.MerchantExcludingIsBlocked["id"];
  logoUrl: bnplMerchants.MerchantExcludingIsBlocked["logo_url"];
  name: bnplMerchants.MerchantExcludingIsBlocked["name"];
  owner: bnplMerchants.MerchantExcludingIsBlocked["owner"];
  registrationNumber: bnplMerchants.MerchantExcludingIsBlocked["registration_number"];
  updated?: Date;
  website: bnplMerchants.MerchantExcludingIsBlocked["website"];
  brandName: bnplMerchants.MerchantExcludingIsBlocked["brand_name"];
};

export const getMerchant = async (): Promise<GetMerchantResponseData> => {
  const response = await bnplMerchants.getApiMerchantsServiceMerchant();

  if (!response.data.data) {
    const errorMessage = "Error getting merchant";
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  const {
    address,
    archived,
    created,
    id,
    logo_url,
    name,
    owner,
    registration_number,
    updated,
    website,
    brand_name,
  } = response.data.data;

  return {
    address,
    archived,
    created: created ? new Date(created) : undefined,
    id,
    logoUrl: logo_url,
    name,
    owner,
    registrationNumber: registration_number,
    updated: updated ? new Date(updated) : undefined,
    website,
    brandName: brand_name,
  };
};
